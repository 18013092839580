<script lang="ts" setup>
import { showToast } from 'vant'
import { CommentApi } from '~/net/apis/comment_api'
import group_seek_film_title from '~/assets/image/home/mine/group_seek_film_title.png'
import upload from '~/assets/image/icon/icon-upload.png'
const props = withDefaults(
  defineProps<{
    show: boolean
  }>(),
  {
    show: false
  }
)
const emits = defineEmits<{
  'update:modelValue': [value: boolean]
  close: [value: boolean]
}>()
function close() {
  emits('update:modelValue', false)
  emits('close', false)
}

const form = ref({})
function afterRead(file: any) {
  // 此时可以自行将文件上传至服务器
  console.log(file)
  // form.value.videoPreviewUrl = file
}
async function submintHandle() {
  const res = await CommentApi.submitVideoAsk({ ...form.value })
  if (res.code === 200) {
    showToast('提交成功')
    close()
  }
}
</script>

<template>
  <van-overlay :show="show" style="z-index: 9999" @click="close">
    <div class="wrapper" @click.stop>
      <div class="block">
        <div absolute right-10 top-2>
          <van-icon flex-end name="cross" size="20" @click="close" />
        </div>
        <img absolute left-25 top-6 h-12 w-39 :src="group_seek_film_title" />
        <div absolute top-12 mt-8 w-full flex flex-col justify-center>
          <van-field v-model="form.videoName" label="片名" placeholder="请输入片名" label-align="top" />
          <van-field v-model="form.videoNo" label="番号" placeholder="请输入番号" label-align="top" />
          <van-field v-model="form.videoActress" label="演员" placeholder="请输入演员名字" label-align="top" />
          <van-field v-model="form.videoDesc" label="描述" placeholder="请输入描述，不超过200个字符。" label-align="top" />
          <div ml-4 ct-e8e8e8>
            截图
            <van-uploader v-model="form.videoPreviewUrl"
            mt-2 :after-read="afterRead"
            :upload-icon="upload"
            upload-text="上传"
            />
          </div>
          <div mt-4 flex justify-center>
            <div round h-11 w-78 text-center ct-ffffff class="bg-bt" @click="submintHandle">提交</div>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<style scoped>
:deep(.van-loading--vertical) {
  justify-content: center;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 350px;
  height: 540px;
  background: url('~/assets/image/home/mine/group_seek_film_bg.png') no-repeat;
  background-position: center center;
  background-size: cover;
  color: #e8e8e8;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 0px #9999990a;
  position: relative;
}
.bg-bt {
  background: linear-gradient(130.94deg, #fd9403 16.87%, #fd5b03 89.42%);
  line-height: 44px;
  border-radius: 10px;
}
:deep(.van-uploader__upload),
:deep(.van-uploader__input) {
  background: #6A6F8C80;
}
:deep(.van-field__label) {
  color: #e8e8e8;
}
:deep(.van-field__control::placeholder) {
  color: #5c5e69;
}
</style>
